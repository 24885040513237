<template>
  <section class="dtc-main-section mt-4" id="">
    <h1 style="font-size: 23px; display: grid; place-items: start;font-family:DFKai-sb;">
      附件5
    </h1>
    <h1 style="font-size: 23px; display: grid; place-items: center;font-family:DFKai-sb;">
      衛生福利部補助計畫收支明細表
    </h1>
    <h3>補助年度:{{ bYear }}</h3>
    <h3>
      計畫名稱: {{ bYear }}學年度第{{ term}}學期「原住民族及離島地區醫事人員養成計畫」
    </h3>
    <table class="table b-table table-bordered">
      <thead>

        <tr>
          <th>序號</th>
          <th>培育學校</th>
          <th v-for="(title,i) in depTitle" :key="i">{{title}}</th>
          <th>小計</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in budgetingsCal">
          <td v-if="item.school!='總人數'">{{i+1}}</td>
          <td class="text-center" :colspan="item.school=='總人數'?2:1">{{item.school}}</td>
          <!-- <td colspan="10"><pre>{{ item.budgetings}}</pre></td> -->
          <td class="text-center" v-for="(depItem,idex) in item.budgetingsCal" >{{depItem.numPeolple}}</td>
        </tr>
        <tr v-if="pageRows.length==0">
          <td colspan="15" class="text-center">
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import html2canvas from "html2canvas";
import queryString from "query-string";
const rowObj = {};
let pageRows = new Array().fill().map((s, t) => {
  return {
    SchoolName: t.SchoolName,
    NumberOfPeople: 0,
    Fee_1: 0,
    Fee_2: 0,
    Fee_3: 0,
    Fee_4: 0,
    Fee_5: 0,
    Fee_6: 0,
    Fee_7: 0,
    Fee_8: 0,
    Fee_9: 0,
    Fee_10: 0,
    Fee_11: 0,
    Fee_12: 0,
    Subtotal: 0
  };
});

export default {
  name: "totalSpend",
  data() {
    return {
      term: 1,
      bYear: "108",
      category: null,
      dtcGridSize: 1,
      schoolName: "",
      pageRows,
      learnFooter: 0,
      lifeFooter: 0,
      travelFooter: 0,
      deviceFooter: 0,
      sumFooter: 0,
      debug: window.dtcDebug,
      supplement: "",
      rTotal: { 'school': '總人數', budgetingsCal: [] },
      depTitle: [],
      budgetingsCal: [],

    };
  },

  methods: {
    normalizeData(map) {
      map.forEach((s, i) => {
        const item = s.budgetingSum;
        this.SumNumberOfPeople += item.NumberOfPeople;
        this.SumFee_1 += item.Fee_1;
        this.SumFee_2 += item.Fee_2;
        this.SumFee_3 += item.Fee_3;
        this.SumFee_4 += item.Fee_4;
        this.SumFee_5 += item.Fee_5;
        this.SumFee_6 += item.Fee_6;
        this.SumFee_7 += item.Fee_7;
        this.SumFee_8 += item.Fee_8;
        this.SumFee_9 += item.Fee_9;
        this.SumFee_10 += item.Fee_10;
        this.SumFee_11 += item.Fee_11;
        this.SumFee_12 += item.Fee_12;
        this.SumSubtotal += item.Subtotal;
      });
    },
    async getData() {
      //this.learnSpendFooter = this.insureFooter = this.othersFooter = 0;
      //this.travelFooter = this.clothFooter = this.bookFooter = this.classFooter = this.allowFooter = this.eatFooter = 0;
      //this.backHomeFooter = this.liveFooter = 0;
      //this.sumFooter = 0;
      //this.resetRows();

      const { byear, term, category } = queryString.parse(
        location.href.split("?")[1]
      );
      this.bYear = +byear;
      this.term = +term;
      const url = `Budgeting/GetListByYear?byear=${byear}&term=${term}&category=${category}`;
      let map = [];
      try {
        map = await window.axios.get(url);

        if (map) {
          //console.log('map', map);
          this.pageRows = map;
          map.forEach((s, i) => {
            //budgetings
            s.budgetings.forEach((item, key) => {
              if (this.depTitle.indexOf(item.MajorId) == -1) {
                this.depTitle.push(item.MajorId);
              }
            });
          });

          //let rTotal = { 'school': '總人數', budgetingsCal: [] };
          this.depTitle.forEach((title, key) => { this.rTotal.budgetingsCal.push({ "dep": title, "numPeolple": 0 }) });
          map.forEach((s, i) => {
            const rSchool = { 'school': s.SchoolName, budgetingsCal: [] };
            this.depTitle.forEach((title, key) => {
              const numPeolple = s.budgetings.filter(k => k.MajorId == title), obj = { "dep": title };

              if (numPeolple.length > 0) {
                obj.numPeolple = numPeolple.reduce((x, y) => x + y.NumberOfPeople, 0);
                this.rTotal.budgetingsCal[key].numPeolple += obj.numPeolple;
              } else {
                obj.numPeolple = 0;
              }
              rSchool.budgetingsCal.push(obj);
            });
            const total = rSchool.budgetingsCal.reduce((x, y) => x + y.numPeolple, 0);
            rSchool.budgetingsCal.push({ 'school': '全部', 'numPeolple': total });
            this.budgetingsCal.push(rSchool);
          });
          const total = this.rTotal.budgetingsCal.reduce((x, y) => x + y.numPeolple, 0);
          this.rTotal.budgetingsCal.push({ 'dep': '全部', numPeolple: total });
          this.budgetingsCal.push(this.rTotal);
          //const schoolName = this.schoolName;
          //this.supplement = map.Supplement;
          //this.normalizeData(map);

        } else {
          //alert(`error server api ${url} return 200 but the data is: ` + map);
          const str = `error server api ${url} return status 200 but the data is null`;
          this.$root.$emit("dtc-server-error", str);
          //this.pageRows = [];
          // user can enter data into sheet; not need to empty rows
        }
      } catch (e) {
        this.debug = true;
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  mounted() {
    this.getData();

  },

};
</script>
<style lang="scss">
main .custom-select {
  background: none;
}
</style>
<style lang="scss">
.dtc-main-section ::v-deep .dropdown-toggle {
  min-width: 57px !important;
  font-size: 14px;
  background: white;
  color: black;

  &::after {
    all: unset;
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-bordered {

  thead,
  tbody,
  tfoot {

    th,
    td {
      border-color: #111 !important;
      border-width: 1px;
      height: 2.5em;
    }
  }
}

.text-right-mode {
  text-align: right;
  padding-right: 5px;
}

@media screen {
  .dtc-main-section {
    max-width: 1500px;
    padding-top: 12px;
    margin: 0 auto;
  }
}

@media print {
  @page {
    size: A4 landscape;
  }

  .dtc-print {
    --dtc-border-color: var(--dark);
    max-width: 98vw;
  }
}
</style>